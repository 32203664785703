<template>
  <PublicView :title="$t('public.passwordRecoveryTitle')">
    <form class="space-y-6" action="#" method="POST">
      <div>
        <p>{{ $t('public.recoveryText') }}</p>
      </div>

      <div>
        <label for="email" class="block text-sm font-medium text-gray-700">
          {{ $t('public.email') }}
        </label>
        <div class="mt-1">
          <input
            v-model="email"
            id="email"
            name="email"
            type="email"
            autocomplete="email"
            required=""
            class="
              appearance-none
              block
              w-full
              px-3
              py-2
              border border-gray-300
              rounded-md
              shadow-sm
              placeholder-gray-400
              focus:outline-none
              focus:ring-ifgreen
              focus:border-ifgreen
              sm:text-sm
            "
          />
        </div>
      </div>

      <div>
        <button
          @click="resetPassword"
          type="submit"
          class="
            w-full
            flex
            justify-center
            py-2
            px-4
            border border-transparent
            rounded-md
            shadow-sm
            text-sm
            font-medium
            text-white
            bg-ifgreen
            hover:bg-ifgreen-dark
            focus:outline-none
            focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen-dark
          "
        >
          {{ $t('public.sendLink') }}
        </button>
      </div>
    </form>

    <div class="mt-6">
      <div class="relative">
        <div class="absolute inset-0 flex items-center">
          <div class="w-full border-t border-gray-300" />
        </div>
        <div class="relative flex justify-center text-sm">
          <span class="px-2 bg-white text-gray-500"> {{ $t('public.or') }} </span>
        </div>
      </div>

      <div class="mt-6 flex justify-between">
        <router-link
          to="/login"
          class="font-medium text-ifgreen hover:text-ifgreen-dark"
        >
          {{ $t('public.login') }}
        </router-link>
        <router-link
          to="/register"
          class="font-medium text-ifgreen hover:text-ifgreen-dark"
        >
          {{ $t('public.createAccount') }}
        </router-link>
      </div>
    </div>
  </PublicView>
</template>

<script>
import { api } from "@/assets/js/api-client.js";
import PublicView from '@/components/layout/PublicView.vue'

export default {
  components: {
    PublicView
  },
  data() {
    return {
      email: "",
    };
  },
  created() {
    console.log(this.$route)
    if (this.$route.query.expired) {
      this.$notify({
        title: this.$t('public.error'),
        text: this.$t('public.infos.passResetLinkExpired'),
        type: "error",
        duration: 10000
      })
    }
  },
  methods: {
    async resetPassword(e) {
      e.preventDefault();
      this.$store.dispatch('setLoading', true)
      api.post('/reset-password', {email: this.email, organisation: process.env.VUE_APP_ORG_ID, language: this.$i18n.locale}).then(result => {
        console.log(result)
        this.$store.dispatch('setLoading', false)
        if (result.data.status == 'success'){
          this.$notify({
            title: this.$t('public.success'),
            text: `${this.$t('public.infos.passResetLinkSentTo')} ${this.email}.`,
            type: "success",
            duration: 10000
          })
        } else if (result.data.status == 'error') {
          this.$notify({
            title: `${this.$t('public.error')}`,
            text: result.data.message,
            type: "error",
            duration: 10000
          })
        }
      }).catch(err => {
        this.$store.dispatch('setLoading', false)
        this.$notify({
          title: `${this.$t('public.error')}`,
          text: err.response.data.detail,
          type: "error",
          duration: 10000
        })
      })
    }
  },
};
</script>
